import { template as template_0f8f9ba28655491db1bbeb62a718a446 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const RepliesCell = template_0f8f9ba28655491db1bbeb62a718a446(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="posts"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="replies"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RepliesCell;
