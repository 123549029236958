import { template as template_ed4f569cb3464b8eaf0d2fff2aa8e3f1 } from "@ember/template-compiler";
const WelcomeHeader = template_ed4f569cb3464b8eaf0d2fff2aa8e3f1(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
