import { template as template_e9e88c6b4f2b49c89d6a024516b52249 } from "@ember/template-compiler";
const FKLabel = template_e9e88c6b4f2b49c89d6a024516b52249(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
